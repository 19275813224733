$color-gradient-1: #ff5a98;
$color-gradient-2: #7638f4;
$color-gradient-3: #11a3d2;



header {
  position: fixed;
  top: 0;

  width: 100%;
  height: 5rem;
  padding: 1rem 2rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  transition: top .25s ease;
  z-index: 1;

  @media screen and (prefers-color-scheme: dark) {
      background: rgba(0, 0, 0, 0.5);
  }

  & #avatar {
    display: flex;
    align-items: center;

    & #divider {
      display: none;
      background-color: #000;
      height: 2.5px;
      width: 5rem;
      transform: translate(0px, 4px);

      @media screen and (prefers-color-scheme: dark) {
        background-color: #fff;
      }
    }

    & img {
      display: none;
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 50%;
      transition: transform .25s ease;

      &:hover {
        transform: scale(1.4);
        
        color: $color-gradient-3;
        cursor: url("../assets/circle.svg") 25 25, auto;
    
        @media screen and (prefers-color-scheme: dark) {
          cursor: url("../assets/circle-white.svg") 25 25, auto;
        }
      }
    }
  }

  & a {
    text-decoration: none;
  }

  & span#works-link, span#schedule-mentoring-link {
    font: 700 italic 1.75rem "Fira Sans";
    background: linear-gradient(
      45deg,
      $color-gradient-1,
      $color-gradient-2,
      $color-gradient-3,
      $color-gradient-1,
      $color-gradient-2,
      $color-gradient-3
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    background-size: 400% 400%;
    animation: gradient 30s ease infinite;

    transition: opacity .25s ease;

    &:hover {
      opacity: .75;
      cursor: url("../assets/circle.svg") 25 25, auto;

    @media screen and (prefers-color-scheme: dark) {
      cursor: url("../assets/circle-white.svg") 25 25, auto;
    }
    }
  }
  

  #social-items {
    display: flex;
    gap: 2rem;

    & .social-item {
      height: 1.5rem;
      width: 1.5rem;
      transition: transform 0.25s ease;

      @media screen and (prefers-color-scheme: dark) {
        fill: #ddd;
      }

      &:hover {
        fill: $color-gradient-3;
        transform: scale(1.1);
      }
    }
  }
}

// MEDIA QUERIES RESPONSIVINESS

@media (min-width: 585px) {
  header #avatar,
  header #avatar #divider,
  header #avatar img {
    display: flex;
    gap: 2rem;
  }
}

@media (min-width: 768px) {
  header {
    padding: 1rem 4rem;
  }
}

@media (min-width: 1024px) {
  header {
    padding: 1rem 6rem;
  }
}
